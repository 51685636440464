/* 2.14 - Accordion - Start
================================================== */
.accordion {
  .accordion-item {
    border: none;
    background-color: transparent;
    margin-bottom: 12px;
  }

  .accordion-new-header{
	border: none !important;
  }
  .accordion-header {
    color: #fff;
    border: 1px solid #0064b4;
    border-radius: 20px;
  }

  .accordion-button {
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    padding: 16px !important;
    color: #0064b4;
    border-radius: 0 !important;
    background-color: transparent;
    font-family: "Axiforma Medium";

    // &:hover {
    // 	color: var(--bs-primary);
    // }

    &:after {
      width: auto;
      height: auto;
      content: "\f068";
      font-size: 16px;
      background: none;
      font-weight: 700;
      font-family: "Font Awesome 5 pro";
    }

    &.collapsed {
      &:after {
        content: "\2b";
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      box-shadow: none;
    }
  }

  .accordion-body {
    padding: 20px 34px;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    color: #78828c;
  }
}

// FAQ Accordion
.faq_accordion {
  .accordion-button {
    font-size: 18px;
    padding: 10px 0 10px;

    &:after {
      width: 40px;
      height: 40px;
      font-size: 18px;
      transform: unset;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      background: #0064b4;
      border: 1px solid #0064b4;
      border-color: #0064b4;
      background-color: #0064b4;
      color: var(--bs-white);
    }

    &.collapsed {
      &:after {
        background: var(--bs-white);
        color: #0064b4;
        border: 1px solid var(--bs-border-color);
      }
    }
  }

  .accordion-button[aria-expanded="true"]:after {
    color: var(--bs-white);
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
  }

  .accordion-body {
    position: relative;
    border-radius: 5px;
    padding: 18px;
  }

  .text_a {
    top: 50px;
    left: 70px;
    line-height: 1;
    font-size: 45px;
    position: absolute;
    color: var(--bs-dark);
    font-family: "Axiforma SemiBold";
  }

  .accordion-item,
  .accordion-body {
    border-color: #e3f0ff;
  }

  .accordion-item:has([aria-expanded="true"]) {
    border-color: transparent;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
}

.faq_section {
  padding-top: 170px;

  .shape_image_1 {
    top: 0;
    right: 0;
    width: 100%;

    img {
      width: calc(100% - 265px);
    }
  }

  .shape_image_2 {
    top: 0;
    right: 0;
    max-width: 265px;
  }
}

/* 2.14 - Accordion - End
================================================== */
