/* 4.02 - Call To Action - Start
================================================== */

.calltoaction_section {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  background-position: center center;
  background: linear-gradient(
    90deg,
    #7dcdbe,
    #0064b4
  ); /* 0° angle (horizontal) */

  .heading_block .heading_text {
    font-size: 54px;
  }

  &:has(.instant_contact_form) {
    background-blend-mode: unset;
  }

  .btn {
    background-color: #0064b4;
    border: none;
  }
}
/* 4.02 - Call To Action - End
================================================== */
