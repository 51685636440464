/* 1.1 - Template Fonts - Start
================================================== */
@font-face {
	font-family: 'Axiforma Regular';
	src: url('../fonts/Axiforma-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Axiforma Medium';
	src: url('../fonts/Axiforma-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Axiforma SemiBold';
	src: url('../fonts/Axiforma-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Axiforma Bold';
	src: url('../fonts/Axiforma-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
/* 1.1 - Template Fonts - End
================================================== */