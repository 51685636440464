/* 4.09 - Service - Start
================================================== */
// Service Block
.service_block {
  position: relative;
  .service_image {
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    border-radius: var(--bs-border-radius-sm);
    img {
      transition: 0.3s;
    }
  }
  &:hover {
    .service_image {
      transform: scale(0.98);
      img {
        transform: scale(1.11);
      }
    }
  }
  .service_content {
    left: 0;
    right: 0;
    bottom: 0;
    bottom: 0;
    z-index: 2;
    padding: 40px;
    position: absolute;
  }
  .service_title {
    font-size: 32px;
    max-width: 360px;
    line-height: 40px;
    margin-bottom: 40px;
    a {
      display: inline;
      color: var(--bs-white);
      background-size: 0 100%;
      backface-visibility: hidden;
      background-position-y: -2px;
      background-repeat: no-repeat;
      transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      background-image: linear-gradient(
        transparent calc(100% - 2px),
        currentColor 2px
      );
      &:hover {
        background-size: 100% 100%;
      }
    }
  }
  .links_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .category_btns_group {
    gap: 10px;
    a {
      color: var(--bs-white);
      border-color: rgba(255, 255, 255, 0.15);
      background-color: rgba(255, 255, 255, 0.1);
      // &:hover {
      //   color: var(--bs-dark);
      //   border-color: var(--bs-white);
      //   background-color: var(--bs-white);
      // }
    }
  }
  .icon_block {
    order: -1;
    width: 50px;
    height: 50px;
    border-color: var(--bs-white);
    background-color: var(--bs-white);
    &:hover {
      color: var(--bs-white);
      background-color: transparent;
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}

////////////////////////////////////////////////////////////////

.service-new-card {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  max-width: 820px;
}

.card--1 .card__img,
.card--1 .card__img--hover {
  // background-image: url('https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.card--2 .card__img,
.card--2 .card__img--hover {
  // background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.card__like {
  width: 18px;
}

.card__clock {
  width: 15px;
  vertical-align: middle;
  fill: #ad7d52;
}
.card__time {
  font-size: 12px;
  color: #ad7d52;
  vertical-align: middle;
  margin-left: 5px;
}

.card__clock-info {
  float: right;
}

.card__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card__info-hover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;
}
.card-new {
  // margin-right: 25px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #f1f6fc;
  height: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}
.card-new:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.1, 1.1);
}

.card__info {
  z-index: 2;
  height: 100%;
  background: linear-gradient(90deg, #7dcdbe, #0064b4);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 24px 24px 24px;
}

.card__category {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.card__title {
  font-size: 18px;
  margin-bottom: 0 !important;
  color: #ffffff;

  a {
    color: #ffffff !important;
  }
}

.card_description {
  display: none;
  font-size: 15px;
  color: #ffffff;
}

.feature-icon {
  display: none;
}

.card__by {
  font-size: 12px;
  font-weight: 500;
}

.card__author {
  font-weight: 600;
  text-decoration: none;
  color: #ad7d52;
}

.card-new:hover .card__img--hover {
  height: 100%;
  opacity: 0.1;
}

.card-new:hover .card__img {
  display: none !important;
}

.card-new:hover .card__info {
  background-color: transparent;
  position: relative;
}

.card-new:hover .card__title {
  font-size: 20px;
  margin-bottom: 12px !important;
}

.card-new:hover .card__info .card_description {
  display: inline;
}

.card-new:hover .card__info .feature-icon {
  display: block;
  font-size: 40px;
  margin-top: 15px;
  color: #ffffff;
}

.card-new:hover .card__info .feature-icon :hover {
  color: #ffffff;
}

.card-new:hover .card__info-hover {
  opacity: 1;
}

////////////////////////////////////////////////////////////////

.about_section {
  .team_cartoon_image {
    text-align: center;
    img {
      // border-radius: 20px;
      // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  .about_content {
    .heading_block {
      margin-bottom: 18px;

      .service-title {
        font-size: 40px;
      }
      h1 {
        color: #0064b4;
        font-size: 36px;
      }
      h2 {
        // background: linear-gradient(
        //   90deg,
        //   #7dcdbe,
        //   #0064b4
        // ); /* 0° angle (horizontal) */
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #0064b4;
        font-size: 36px;
      }
      .heading_text {
        color: #0064b4 !important;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: 8px;
        }
      }
      .icon_list {
        color: #78828c;

        .icon_list_icon {
          color: #78828c;
        }
        .icon_list_text {
          color: #78828c !important;
        }
      }
    }
  }
}

.faq_accordion_new {
  .accordion-item {
    border: none;
    margin-bottom: 12px;
  }
  .accordion-item-new {
    .accordion-header {
      // background: linear-gradient(90deg, #7dcdbe, #0064b4);
      padding: 0px 16px;
      border-color: #0064B4;
      border-radius: 10px;
      .accordion-button {
        color: #0064B4;
      }
    }
    .accordion-body {
      background: none;

      h6 {
        color: #0064B4;
        font-size: 24px;
      }

      p {
        color: #78828C;
      }

      ul{
        li{
          color: #78828c;
        }
      }

      .category_btns_group a {
        // background: linear-gradient(180deg, #7dcdbe, #0064b4);
        border-color: #0064B4;
        color: #0064B4;
      }
    }
  }
}

.normal-bg{
  border: 1px solid #0064B4;
  height: 100%;

  h4{
    color:#0064B4 !important ;
  }
  p{
    color: #78828C;
  }

}

.gradient-bg {
  background: linear-gradient(90deg, #7dcdbe, #0064b4) !important;
}

// Service Block 2
.service_section {
  .service-card {
    border-radius: 18px;
    background-color: aliceblue;
    height: 100%;
    box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
    border: none;
    transition: 300ms ease;

    .service-title {
      background: linear-gradient(
        90deg,
        #7dcdbe,
        #0064b4
      ); /* 0° angle (horizontal) */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #fff;
    }
    img {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      object-fit: cover;
      height: 250px;
    }
    .card-body {
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .main-div {
    border-radius: 0 32px;
    padding: 35px;
    background-color: #FFF;

    h5{
      color: #0064B4;
    }

    p{
      color: #78828C;
    }
  }

  .why-us-div{
    background-color: #FFF;
    h5{
      color: #0064B4;
    }

    p{
      color: #78828C;
    }
  }

  .main-second-div {
    border-radius: 32px;
    padding: 35px;
    border-color: #0064B4;
    // box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
    //   -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
    font-family: sans-serif;

    h5{
      color: #0064B4;
    }

    p{
      color: #78828C;
    }
  }

  .main-second-div:hover {
    transform: scale(0.9, 0.9);
    // box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    //   -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
  }

  .shape_image_1 {
    left: 0;
    top: 50%;
    max-width: 190px;
    transform: translateY(-50%);
  }
  .shape_image_2 {
    top: 30%;
    right: 0;
    max-width: 270px;
  }
  .shape_image_3 {
    top: 0;
    right: -10px;
    width: calc(100% - 265px);
  }
  .shape_image_4 {
    right: 0;
    bottom: 0;
    max-width: 265px;
  }
  .shape_image_5 {
    top: 0;
    left: 0;
    max-width: 265px;
  }
}
.service_block_2 {
  // border: 1px solid #00297a;
  padding: 40px;
  transition: var(--bs-transition);
  background-color: var(--bs-white);
  border-radius: 32px;
  // box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
  height: 100%;
  // clip-path: polygon(80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
  .service_icon {
    margin-bottom: 40px;
  }
  .service_title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 30px;
    a {
      color: #ffffff;
      // &:after {
      // 	opacity: 0;
      // 	content: '\f061';
      // 	font-weight: 700;
      // 	margin-left: 10px;
      // 	color: var(--bs-dark);
      // 	display: inline-block;
      // 	transform: translateX(-5px);
      // 	transition: var(--bs-transition);
      // 	font-family: 'Manrope-Regular';
      // }
      &:hover {
        color: var(--bs-primary);
        // &:after {
        // 	color: var(--bs-primary);
        // }
      }
    }
  }
  .icon_list {
    gap: 10px;
    > li {
      line-height: 1;
      border-radius: 5px;
      padding: 11px 10px 11px 0;
      background: linear-gradient(90deg, transparent, var(--bs-light));
    }
    .icon_list_icon {
      color: var(--bs-primary);
    }
    .icon_list_text {
      margin-top: 2px;
      color: #78828c;
    }
  }

  &:hover {
    .service_title a {
      &:after {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}
.pt-175 {
  padding-top: 175px;
}
.pb-80 {
  padding-bottom: 80px;
}
// Service Facilities Group
.service_facilities_group {
  margin: -10px;
  > li {
    padding: 10px;
    flex: 0 0 50%;
  }
  .iconbox_block.layout_icon_left {
    display: flex;
    align-items: center;
    padding: 16px 20px 16px 16px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .iconbox_icon {
      width: 42px;
      height: 42px;
      margin: 0 16px 0 0;
      border-radius: 6px;
      img {
        max-width: 22px;
      }
    }
    .iconbox_title {
      font-size: 16px;
      line-height: 20px;
      color: #001e3c;
      // background: linear-gradient(
      //   180deg,
      //   #7dcdbe,
      //   #0064b4
      // ); /* 0° angle (horizontal) */
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    &:hover {
      .iconbox_title {
        background: linear-gradient(
          90deg,
          #7dcdbe,
          #0064b4
        ); /* 0° angle (horizontal) */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

// Service Pill Sarousel
.service_pill_carousel {
  position: relative;
  pointer-events: none;
  &:before,
  &:after {
    top: 0;
    bottom: 0;
    z-index: 2;
    content: "";
    width: 150px;
    position: absolute;
  }
  &:before {
    left: 0;
    background-image: linear-gradient(90deg, var(--bs-white), transparent);
  }
  &:after {
    right: 0;
    background-image: linear-gradient(90deg, transparent, var(--bs-white));
  }
  .swiper-wrapper {
    transition-timing-function: linear !important;
    &:hover {
      animation: none;
    }
  }
  .service_pill_block {
    display: flex;
  }
}

.service_pill_block {
  gap: 15px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px;
  align-items: center;
  display: inline-flex;
  color: var(--bs-dark);
  padding: 6px 20px 6px 6px;
  font-family: "Manrope-Medium";
  border: 1px solid var(--bs-border-color);
  i {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    border-radius: 100%;
    align-items: center;
    display: inline-flex;
    color: var(--bs-white);
    justify-content: center;
    background-color: var(--bs-primary);
  }
}
/* 4.09 - Service - End
================================================== */

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  overflow: hidden;
  height: 100%;
  border-radius: 18px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  height: 100%;
  // background: linear-gradient(90deg, #7dcdbe, #0064b4);
  background: #fff;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  overflow: hidden;

  font-weight: bold;
  font-size: 18px;
  color: #0064b4;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
// .ag-courses-item_bg {
//   height: 128px;
//   width: 128px;
//   background: linear-gradient(90deg, #7dcdbe, #0064b4);


//   z-index: 1;
//   position: absolute;
//   top: -135px;
//   right: -75px;

//   border-radius: 50%;

//   -webkit-transition: all 0.5s ease;
//   -o-transition: all 0.5s ease;
//   transition: all 0.5s ease;
// }

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}
