/* 5.01 - About Page - Start
================================================== */

:root {
	// Basic
	--c-white: #fff;
	--c-black: #000;

	// Greys
	--c-ash: #eaeef6;
	--c-charcoal: #a0a0a0;
	--c-void: #141b22;

	// Beige/Browns
	--c-fair-pink: #FFEDEC;
	--c-apricot: #FBC8BE;
	--c-coffee: #754D42;
	--c-del-rio: #917072;

	// Greens
	--c-java: #1FCAC5;

	// Purples
	--c-titan-white: #f1eeff;
	--c-cold-purple: #a69fd6;
	--c-indigo: #6558d3;
	--c-governor: #4133B7;
}


.about_image_1 {
	position: relative;
	// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 20px !important;
	margin-top: 5%;
	img {
		border-radius: 20px ;
		&:nth-child(2) {
			top: 170px;
			left: 200px;
			max-width: 75px;
			position: absolute;
		}
		&:nth-child(3) {
			right: 40px;
			bottom: 105px;
			max-width: 80px;
			position: absolute;
		}
	}
}

.about_and_case_section {
	padding: 90px 0 81px;
	// background: #C8D2DC;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	.heading_text{
		color: #0064B4 !important;
	}
	.shape_image_1 {
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		img {
			width: calc(100% - 530px);
			transform: translateX(5px);
		}
	}

	h3 {
		color: #0064B4;
		font-size: 24px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 4px;
	  }
	  
	  p {
		font-size: 17px;
		font-weight: 400;
		line-height: 20px;
		color: #78828C;
	  
		&.small {
		  font-size: 14px;
		}
	  }

	.card4 {
		display: block;
		top: 0px;
		position: relative;
		width: 100%;
		// max-width: 262px;
		background-color: #ffffff;
		border-radius: 4px;
		padding: 32px 24px;
		margin: 12px;
		text-decoration: none;
		overflow: hidden;
		border: 1px solid #0064B4;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 20px;
		
		&:hover {
		  border: 1px solid #0064b4;
		}
		
		h3 {margin-top: 8px;}
	  }
	  

	.information {
		background-color: var(--c-white);
		padding: 1.5rem;
		height: 100%;
		border: none;
	
		.tag {
			display: inline-block;
			background-color: var(--c-titan-white);
			color: var(--c-indigo);
			font-weight: 600;
			font-size: 0.875rem;
			padding: 0.5em 0.75em;
			line-height: 1;
			border-radius: 6px;
			& + * {
				margin-top: 1rem;
			}
		}
	
		.title {
			font-size: 1.5rem;
			color: #0064B4;
			line-height: 1.25;
			margin-bottom: 3rem;
			& + * {
				margin-top: 1rem;
			}
		}
	
		.info {
			color: var(--c-charcoal);
			& + * {
				margin-top: 1.25rem;
			}
		}

		p{
			color: #78828C;
		}
	
		.button {
			font: inherit;
			position: absolute;
			bottom: 20px;
			line-height: 1;
			border: 2px solid #0064B4;
			color: #0064B4;
			padding: 0.5em 1em;
			border-radius: 6px;
			font-weight: 500;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.5rem;
			&:hover,
			&:focus {
				background-color: #0064B4;
				color: var(--c-white);
			}
		}
		
		.details {
			display: flex;
			gap: 1rem;
			div {
				padding: .75em 1em;
				background-color: var(--c-titan-white);
				border-radius: 8px;
				display: flex;
				flex-direction: column-reverse;
				gap: .125em;
				flex-basis: 50%;
			}
			
			dt {
				font-size: .875rem;
				color: var(--c-cold-purple);
			}
			
			dd {
				color: var(--c-indigo);
				font-weight: 600;
				font-size: 1.25rem;
			}
		}
	}
}
.about_image_2 {
	gap: 20px;
	display: flex;
	position: relative;
	align-items: flex-start;
	margin-left: 50px;
	.image_wrap {
		flex: 0 0 auto;
		max-width: 198px;
		overflow: hidden;
		border-radius: var(--bs-border-radius);

		img{
			border-radius: 20px;
		}
	}
	.space_line {
		top: 142px;
		left: 218px;
		max-width: 368px;
		position: absolute;
	}
}

.about_funfact_info {
	z-index: 1;
	width: 472px;
	flex: 0 0 auto;
	padding: 20px 30px;
	position: relative;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	.customer_count {
		>ul {
			margin-bottom: 12px;
			>li {
				width: 50px;
				height: 50px;
				font-size: 15px;
				overflow: hidden;
				line-height: 28px;
				border-radius: 100%;
				align-items: center;
				display: inline-flex;
				color: var(--bs-white);
				justify-content: center;
				border: 2px solid var(--bs-white);
				background-color: var(--bs-primary);
				&:not(:first-child) {
					margin-left: -15px;
				}
			}
		}
	}
	.about_funfact_counter {
		gap: 50px;
		display: flex;
		margin-top: 46px;
		justify-content: flex-end;
		.counter_value {
			line-height: 1;
			font-size: 45px;
			font-weight: 700;
			display: inline-flex;
			color: var(--bs-dark);
			font-family: 'Axiforma Bold';
		}
		.funfact_title {
			color: #676767;
			font-size: 16px;
			font-weight: 400;
			font-family: 'Axiforma Regular';
		}
	}
	.btn {
		top: 0;
		right: -5px;
		position: absolute;
		border-radius: var(--bs-border-radius);
		padding: 0 20px;
	}
	.icon_globe {
		left: 22px;
		bottom: 22px;
		max-width: 40px;
		position: absolute;
		&:before,
		&:after {
			top: 0;
			left: 0;
			width: 100%;
			content: '';
			height: 100%;
			position: absolute;
			border-radius: 100%;
			transition: all 0.33s ease;
			animation: ripple 1.5s linear infinite;
			border: 1px solid var(--bs-white);
		}
		&:after {
			animation-delay: 0.9s;
		}
	}
}

.about_image_3 {
	position: relative;
	.image_wrap {
		overflow: hidden;
		border-radius: var(--bs-border-radius);

		img{
			border-radius: 20px;
		}
	}
	.funfact_block {
		left: -154px;
		bottom: 85px;
		position: absolute;
		.funfact_icon {
			background-color: #FFBE16;
		}
	}
}

// About Page 
// body:has(.page_banner_section + .intro_about_section) .page_banner_section {
// 	padding-bottom: 394px;
// }
.intro_about_section {
	.intro_about_content {
		z-index: 1;
		position: relative;
		margin: -420px 0 60px;
		.image_wrap {
			overflow: hidden;
			border-radius: var(--bs-border-radius-sm);

			img{
				border-radius: 20px;
			}
		}
	}

	.heading_description{
		color: #78828C;
	}

	.gradient-text {
		background: linear-gradient(90deg, #7DCDBE, #0064B4); /* 0° angle (horizontal) */
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	  }
}

.about-page {
	.page_banner_section {
		// background-image: none!important;
	}
	.client_logo_section {
		background-image: none!important;
	}
}

.service-single-page {
	overflow: hidden;
	.process_technology_review_section {
		padding-top: 80px;

		@media(max-width:991px){
			padding-top: 30px;
		}

		.decoration_item {
	       display: none;
		}
	}
}

.site_footer {
	overflow: hidden;
}
/* 5.01 - About Page - End
================================================== */

