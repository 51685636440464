/* 2.90 - Form - Start
================================================== */
// Input

.New-Form{
  .errorMessage {
    color: #001e3c !important;
    margin-top: 5px;
  }
}
.form-group {
  position: relative;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .errorMessage {
    color: #ff6c6c;
    margin-top: 5px;
  }

  .contact-input {
    // background-color: #e8ecf1 !important;

    &::placeholder {
      color: #9bacbf !important;
      font-size: 16px;
      transform: translateY(3px);
    }

    color: #9bacbf !important;
	box-shadow: none !important;
	border: 1px solid #9bacbf  !important;
  }

  .contact-label {
    // background: linear-gradient(
    //   90deg,
    //   #7dcdbe,
    //   #0064b4
    // ); /* 0° angle (horizontal) */
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: #0064B4;
  }

  .form-control,
  .form-select {
    padding: 0 24px;
    font-size: 16px;
    color: var(--bs-dark);
    transition: 0.3s ease;
    caret-color: var(--bs-primary);
    background-color: var(--bs-white);
    border-radius: var(--bs-border-radius);
    border: 1px solid var(--bs-border-color);
    box-shadow: 0 20px 30px 0 rgba(174, 191, 210, 0.3);
    // &:focus {
    // 	border-color: var(--bs-primary);
    // }

    &::placeholder {
      color: #676767;
      font-size: 16px;
      transform: translateY(3px);
    }
    &:-ms-input-placeholder {
      color: #676767;
      font-size: 16px;
      transform: translateY(3px);
    }
    &::-ms-input-placeholder {
      color: #676767;
      font-size: 16px;
      transform: translateY(3px);
    }
  }

  input.form-control,
  select.form-select {
    height: 60px;
  }
  textarea.form-control {
    min-height: 180px;
    border-radius: 12px;
    padding: 15px 25px 25px;
  }
  .form-select {
    color: #8c8f92;
  }
}

.input_title {
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
  color: var(--bs-dark);
  font-family: "Axiforma SemiBold";
}

// Search Form
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.search_form {
  button[type="submit"] {
    top: 19px;
    right: 20px;
    opacity: 0.5;
    position: absolute;
    &:hover {
      opacity: 1;
    }
    img {
      width: 16px;
    }
  }
}

// Check Box
.form-check {
  padding-left: 30px;
  .form-check-input {
    width: 20px;
    height: 20px;
    box-shadow: none;
    margin: 0 0 0 -30px;
  }
  .form-check-label {
    cursor: pointer;
  }
}
/* 2.90 - Form - End
================================================== */
