/* 3.03 - Page Header, Page Banner, Breadcrumb - Start
================================================== */
.page_banner_section {
	z-index: 1;
	background-image: url('../../images/hero/newback.jpg') !important;
	position: relative;
	padding: 130px 0 110px;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #001E3C;
	background-position: center center;
	background-blend-mode: color-dodge;
	// background-position: top;
	// background-image: none !important;
	.heading_focus_text {
		margin-bottom: 24px;
	}
}

.page_title {
	font-size: 55px;
	line-height: 70px; 
}
/* 3.03 - Page Header, Page Banner, Breadcrumb - End
================================================== */