/* 4.04 - Client Logo - Start
================================================== */
.client_logo_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-color: #001E3C;
  background-position: center center;

  .mission-vision-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    padding: 20px;

    .card {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      width: 30%;
      text-align: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      position: relative;
      transition: transform 0.3s ease, box-shadow 0.3s ease,
        border-top-color 0.3s ease;
      padding-bottom: 40px;

      .icon {
        font-size: 50px;
        margin-bottom: 10px;
        color: #0064b4;
      }

      h3 {
        margin: 10px 0;
        font-size: 1.75rem;
        color: #0064b4;
      }

      p {
        font-size: 16px;
        line-height: 1.6;
        color: #78828c;
        text-align: justify;
      }

      &:after {
        content: "";
        position: absolute;
        width: 80px;
        height: 4px;
        background-color: transparent;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2px;
      }

      &.mission {
        border-top: 30px solid #0064b4;
        // &:after {
        //   background-color: #f44336;
        // }

        &:hover {
          // border-top-color: darken(#7DCDBE, 10%);
          transform: scale(1.05); /* Slightly scale the card on hover */
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
        }
      }

      &.vision {
        border-top: 30px solid #0064b4;
        // &:after {
        //   background-color: #00bcd4;
        // }

        &:hover {
          // border-top-color: darken(#7DCDBE, 10%);
          transform: scale(1.05); /* Slightly scale the card on hover */
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
        }
      }

      &.values {
        border-top: 30px solid #0064b4;

        // &:after {
        //   background-color: #ffc107;
        // }

        &:hover {
          // border-top-color: darken(#7DCDBE, 10%);
          transform: scale(1.05); /* Slightly scale the card on hover */
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
        }
      }
    }

    .connector {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5%;
      position: relative;

      .circle {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border: 5px solid #0064b4; /* Change color as needed */
        border-radius: 50%;
        z-index: 1;
      }
    }

    .line {
      position: absolute;
      top: 50%;
      left: 10%; /* Adjust as needed */
      right: 10%;
      height: 4px;
      background-color: #0064b4; /* Line color */
      z-index: 0;
    }

    @media (max-width: 1024px) {
      .card {
        width: 50%;
		margin-bottom: 20px; /* Cards take more space as screen size reduces */
      }

      .connector {
        display: none; /* Remove the connector lines on small screens */
      }

      .line {
        display: none; /* Hide the horizontal line on small screens */
      }
    }

    @media (max-width: 768px) {
      flex-direction: column; /* Stack cards vertically on smaller screens */
      justify-content: center;

      .card {
        width: 100%;
        margin-bottom: 20px;
      }

      .connector {
        display: none; /* Remove the connector lines on small screens */
      }

      .line {
        display: none; /* Hide the horizontal line on small screens */
      }
    }

    @media (max-width: 480px) {
      .card {
        width: 90%; /* Full width on very small screens */
      }
    }
  }
}

.client_logo_carousel {
  .slick-slide {
    padding: 0 5px;
  }
}

.client_logo_item {
  height: 118px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4eef0;
  transition: var(--bs-transition);
  background-color: var(--bs-white);
  border-radius: var(--bs-border-radius);
  &:hover {
    box-shadow: 0 8px 15px 0 rgba(3, 11, 76, 0.08);
  }
  img {
    max-width: 124px;
  }
}
.no_style {
  .client_logo_item {
    padding: 0;
    border: none;
    height: 60px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
}

.feature_partners_section {
  padding: 70px 0;
  padding-bottom: 20px;
  background-color: #0132b8;
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    height: 100%;
    content: "";
    background-color: #0132b8;
    z-index: 2;
  }
  .title_text {
    top: -40px;
    left: 5px;
    bottom: -40px;
    z-index: 2;
    padding: 0 40px;
    font-size: 20px;
    font-weight: 600;
    max-width: 240px;
    position: absolute;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: #0132b8;
    font-family: "Axiforma SemiBold";
  }
  .client_logo_carousel .swiper-wrapper .swiper-slide {
    padding: 30px 8px;
  }
  .client_logo_item {
    height: 70px;
    border: none;
    display: flex;
    border-radius: 5px;
    align-items: center;
    clip-path: polygon(84% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
    img {
      max-width: 90px;
    }
  }

  .slick-list {
    overflow: unset;
  }
}
/* 4.04 - Client Logo - End
================================================== */
