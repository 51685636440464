/* 2.03 - Template Gapping or Spacing - Start
================================================== */
.section_space {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section_space2 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.career-openings {
  h3 {
   color: #0064B4;
  }
  p{
    color: #78828C;
  }
  ul{
    li{
      color: #78828C;
    }
  }
  
}

// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
/* 2.03 - Template Gapping or Spacing - End
================================================== */
