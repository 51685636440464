/* 4.06 - Policy - Start
================================================== */
.policy_section {
	background-color: #001E3C;
	background-image: url("../../images/about/bg.jpg");
	background-repeat: no-repeat;
    background-blend-mode: luminosity;
	background-size: cover;
	background-position: top;
	.iconbox_block.layout_icon_left {
		padding: 0;
		box-shadow: none;
		transform: unset;
		background-color: transparent;
	}
	.iconbox_title{
		// background: linear-gradient(45deg, #7DCDBE, #0064B4); /* 0° angle (horizontal) */
		// -webkit-background-clip: text;
		// -webkit-text-fill-color: transparent;
	}
	.iconbox_title2{
		// background: linear-gradient(90deg, #7DCDBE, #0064B4); /* 0° angle (horizontal) */
		// -webkit-background-clip: text;
		// -webkit-text-fill-color: transparent;
		color: #0064B4;
	}

	p{
		color: #78828C;
	}
}
/* 4.06 - Policy - End
================================================== */