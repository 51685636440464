/* 2.13 - Rating Star - Start
================================================== */
.rating_block {
  gap: 2px;
  li {
    line-height: 1;
    font-size: 14px;
    i {
      background: linear-gradient(
        90deg,
        #7dcdbe,
        #0064b4
      ); /* 0° angle (horizontal) */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
/* 2.13 - Rating Star - End
================================================== */
