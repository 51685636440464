/* 2.15 - Pagination Nav - Start
================================================== */
.pagination_wrap {
  padding: 40px 0;
}
.pagination_nav {
  gap: 15px;
  a {
    width: 50px;
    height: 50px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 54px;
    border-radius: 5px;
    text-align: center;
    background: linear-gradient(90deg, #7DCDBE, #0064B4); /* 0° angle (horizontal) */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Axiforma Medium";
    background-color: var(--bs-white);
    box-shadow: 0 20px 30px 0 rgba(174, 191, 210, 0.3);
  }
  >li {
    &:hover:not(.active) {
      >a {
        color: var(--bs-white);
        background-color: var(--bs-primary);
      }
    }
    &.active {
      >a {
        color: var(--bs-white);
        background-color: var(--bs-dark);
      }
    }
  }
}
/* 2.15 - Pagination Nav - End
================================================== */