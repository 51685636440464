/* 2.16 - Icon Block - Start
================================================== */
.icon_block {
	width: 60px;
	height: 60px;
	flex: 0 0 auto;
	font-size: 20px;
	overflow: hidden;
	border-radius: 100%;
	align-items: center;
	display: inline-flex;
	color: var(--bs-dark);
	justify-content: center;
	border: 1px solid var(--bs-light);
	background-color: var(--bs-light);
	&:hover {
		color: var(--bs-white);
		border-color: var(--bs-primary);
		background-color: var(--bs-primary);
	}
}
/* 2.16 - Icon Block - End
================================================== */